<template>
  <b-container>
    <b-card bg-variant="light" class="text-center mb-5">
      <b-card-title class="text-center mb-4">{{ $t('vacation.vacationOverview') }}</b-card-title>
      <!-- use this with a 'key' to reload the vacation Info when the absence list changes -->
      <VacationOverview
        :user-id="this.$store.getters.getCurrentUser.id"
        :key="allAbsencesChangedTimestamp"
        class="mt-3"
      />
    </b-card>
    <b-card bg-variant="light" class="text-center">
      <b-card-title class="text-center">{{ this.vacationTitle }}</b-card-title>
      <div class="mb-5">
        <div v-for="(year, index) in yearFilter" :key="year.value" class="d-flex justify-content-center">
          <b-row v-if="allAbsences !== null && allAbsences.length > 0" class="text-center" align-v="center">
            <b-col class="year-label" v-if="yearFilterAllYears" text-align-c> {{ year }} </b-col>
            <b-col :cols="yearFilterAllYears ? '10' : '12'">
              <HeatMap
                :id="'heatMap-' + index"
                type="heatmap"
                title=""
                :colors="['#ebedf0', '#369AA0', '#1F4F6F', '#df7373', '#f4a259']"
                :discrete-domains="false"
                :all-absences="allAbsences"
                :height="170"
                :year="year"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <AbsenceTable
        :all-absences="allAbsences"
        :user-list="userList"
        :reload-function="reloadData"
        :manage-all-users="false"
        @year-change="yearFilter = $event"
      />
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import 'vue2-datepicker/index.css'
import HeatMap from '@/components/charts/HeatMap'
import AbsenceTable from '@/components/AbsenceTable'
import VacationOverview from '@/components/VacationOverview'
import { LocalDate } from '@/util/LocalDateTimeFormatter'

export default {
  name: 'AccountAbsence',
  mixins: [ApiMixin, NotificationMixin],
  components: { HeatMap, AbsenceTable, VacationOverview },
  data() {
    return {
      allAbsences: null,
      yearFilter: [],
      allAbsencesChangedTimestamp: null,
      userList: []
    }
  },
  created() {
    this.userList = [this.$store.getters.getCurrentUser]
    this.loadAbsences()
  },
  computed: {
    vacationTitle() {
      let title = this.$t('vacation.vacationTable')
      return this.yearFilter.length === 1 ? title + '  ' + this.yearFilter : title + ' ' + this.$t('absence.total')
    },
    yearFilterAllYears() {
      return this.yearFilter.length !== 1
    }
  },
  methods: {
    loadAbsences() {
      let self = this
      return this.getRequest(
        '/absences',
        new RequestConfig().onSuccess(res => {
          self.allAbsences = res.data
          self.allAbsencesChangedTimestamp = LocalDate.getISOTimestamp()
        })
      )
    },
    reloadData() {
      this.loadAbsences()
    }
  }
}
</script>
<style lang="scss">
.year-label {
  color: #034286;
  text-align: center;
  font-size: 1.5em;
  font-weight: 600;
  font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}
</style>
