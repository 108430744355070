export const VacationDateUtil = {
  minDate(vacations, minDate = new Date()) {
    if (!vacations) {
      let startOfYear = new Date()
      startOfYear.setMonth(0)
      startOfYear.setDate(0)
      return startOfYear
    }
    return vacations.reduce(
      (accumulator, vacation) => (new Date(vacation.start) < accumulator ? new Date(vacation.start) : accumulator),
      minDate
    )
  },
  maxDate(vacations, maxDate = new Date()) {
    if (!vacations) {
      let endOfYear = new Date()
      endOfYear.setMonth(11)
      endOfYear.setDate(31)
      return endOfYear
    }
    return vacations.reduce(
      (accumulator, vacation) => (new Date(vacation.end) > accumulator ? new Date(vacation.end) : accumulator),
      maxDate
    )
  }
}
