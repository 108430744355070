<template>
  <div id="app">
    <vue-frappe
      type="heatmap"
      :key="year"
      :id="id"
      :title="title"
      :colors="colors"
      :discrete-domains="false"
      :data-points="dataPoints"
      :start-date="startDate"
      :end-date="endDate"
      :height="height"
    >
    </vue-frappe>
  </div>
</template>

<script>
import { VueFrappe } from 'vue2-frappe'
import { VacationDateUtil } from '@/util/VacationDateUtil'
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'

export default {
  name: 'HeatMap',
  components: { VueFrappe },
  props: {
    id: {
      required: true,
      type: String,
      default: null
    },
    title: {
      required: false,
      type: String
    },
    colors: {
      required: false,
      type: Array
    },
    allAbsences: {
      required: true,
      type: Array
    },
    height: {
      required: false,
      type: Number
    },
    year: {
      required: false,
      type: Number,
      default: null
    }
  },
  computed: {
    startDate() {
      return this.year !== null
        ? new Date(LocalDateTimeFormatter.toDate(`${this.year}-01-01`))
        : VacationDateUtil.minDate(this.allAbsences)
    },
    endDate() {
      return this.year !== null
        ? new Date(LocalDateTimeFormatter.toDate(`${this.year}-12-31`))
        : VacationDateUtil.maxDate(this.allAbsences)
    },
    dataPoints() {
      let dataPoints = {}
      this.allAbsences.forEach(vacation => {
        this.daysOfVacation(vacation).forEach(vDate => {
          // we need to feed the vue-frappe chart with seconds instead of milliseconds
          dataPoints[vDate / 1000] = this.getHeatMapValueForType(vacation.type)
        })
      })
      return dataPoints
    }
  },
  methods: {
    getHeatMapValueForType(type) {
      switch (type) {
        case 'PARENTAL_LEAVE':
          return 5
        case 'SICK_LEAVE':
          return 4
        case 'CHILD_SICK_LEAVE':
          return 3
        case 'UNPAID_VACATION':
          return 2
        case 'PAID_VACATION':
        case 'SPECIAL_LEAVE':
          return 1
        case 'FLEXTIME':
          return 0
      }
    },
    daysOfVacation: function (vacation) {
      let daysInBetween = []
      let currentDate = new Date(vacation.start)
      let vacationEnd = new Date(vacation.end)
      while (currentDate <= vacationEnd) {
        daysInBetween.push(new Date(currentDate).valueOf())
        currentDate = new Date(currentDate).setDate(new Date(currentDate).getDate() + 1)
      }
      return daysInBetween
    }
  }
}
</script>

<style>
.chart-legend {
  display: none;
}
</style>
